import { bool, node, string } from 'prop-types';

const Label = ({
  children,
  center = false,
  for: For,
  hidden = false,
  id = null,
}) => {
  const classNames = ['text-label-color', 'pb-1'];
  if (hidden) classNames.push('hidden');

  if (center) {
    classNames.push('block');
    classNames.push('text-center');
  }

  const className = classNames.join(' ');

  return (
    <label id={id} hidden={hidden} htmlFor={For} className={className}>
      {children}
    </label>
  );
};

Label.propTypes = {
  children: node.isRequired,
  center: bool,
  for: string.isRequired,
  hidden: bool,
  id: string,
};

export default Label;
