'use client';

import { useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';

import { useClients } from '../../hooks';

const ClientName = () => {
  const [clientId, setClientId] = useState('');
  const searchParams = useSearchParams();

  const { client, getClient } = useClients();

  useEffect(() => {
    const id = searchParams.get('client_id');
    setClientId(id || process.env.NEXT_PUBLIC_AUTH_PROVIDER_MY);
  }, [searchParams]);

  useEffect(() => {
    if (!clientId) return;

    getClient(clientId);
  }, [getClient, clientId]);

  return <strong>{client.name}</strong>;
};

export default ClientName;
