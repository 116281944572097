import { bool, string } from 'prop-types';
import { forwardRef } from 'react';

const Input = forwardRef(
  ({ invalid, role = 'input', disabled = false, ...props }, ref) => {
    const classNames = [
      'text-input-text-color',
      'block',
      'w-full',
      'h-10',
      'py-1.5',
      'px-2.5',
      'bg-clip-padding',
      'rounded',
      'border',
      'border-input-border-color',
      'focus:outline-0',
      'focus:text-input-color',
      'focus:border',
      'focus:border-input-border-color',
      'placeholder:text-input-placeholder-text-color',
    ];

    if (disabled) {
      classNames.push('opacity-60');
      classNames.push('outline-0');
    }

    if (invalid) {
      classNames.push('!border-input-border-invalid-color');
      classNames.push('focus:text-input-text-color');
      classNames.push('focus:border-input-border-invalid-color)');
    }

    if (role === 'card') {
      classNames.push('bg-card-background-color');
      classNames.push('focus:!bg-card-background-color');

      if (invalid) {
        classNames.push('focus:!bg-card-background-color');
      }
    } else if (role === 'input') {
      classNames.push('bg-input-background-color');
      classNames.push('focus:!bg-input-background-color');

      if (invalid) {
        classNames.push('focus:!bg-input-background-color');
      }
    }

    return (
      <input
        readOnly={disabled}
        autoComplete="off"
        ref={ref}
        className={classNames.join(' ')}
        disabled={disabled}
        {...props}
      />
    );
  }
);

Input.propTypes = { invalid: bool, disabled: bool, role: string };

Input.displayName = 'Input';

export default Input;
